import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Payment,PaymentResponse  } from '../models/payment.model';


@Injectable({ providedIn: 'root' })
export class PaymentService {
    constructor(private http: HttpClient) { }


    initPayment(payment:Payment){
      return this.http.post<PaymentResponse>(`${environment.api_url}/api/payment`,payment);
    }

}
