<div class="container-fluid">
  <app-pagetitle title="Cart" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="bg-light">
                <tr>
                  <th style="width: 120px">Product</th>
                  <th>Product Desc</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let line of (cartData|async)?.lines;let inx = index">
                  <td>
                    <img src="{{ line.product.featuredImage.url }}" alt="product-img" title="product-img" class="avatar-md" />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <a [routerLink]="['/ecommerce/products',line.product.id ]"
                        class="text-dark">{{ line.product.title }}</a></h5>
                    <p class="mb-0"><span class="font-weight-medium">{{ line.variant.title !="Default Title"?line.variant.title:'' }}</span></p>
                  </td>
                  <td>
                    {{ line.variant.priceV2 }}
                  </td>
                  <td>
                    <div style="width: 120px;">
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                        <input
                          type="number"
                          [(ngModel)]="line.quantity"
                          (change)="updateQuantity($event.target, inx)"
                          class="form-control text-right"
                          [value]="line.quantity"
                          >
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ (line.quantity * line.variant.priceV2) | number }}
                  </td>
                  <td style="width: 90px;" class="text-center">
                    <a
                      href="javascript:void(0);"
                      (click)="removeItem(inx)"
                      class="action-icon text-danger"
                      > <i
                        class="mdi mdi-trash-can font-size-18"></i></a>
                  </td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Sub Total :</th>
                  <td>{{subTotal}}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Discount :</th>
                  <td>{{discount}}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Shipping Charge :</th>
                  <td>{{shippingCharge}}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="5">Total :</th>

                  <td >{{total}}

                    <!--
                    <input type="number" [(ngModel)]="total" name="total" class="form-control text-right">
                    -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr class="my-4">
          <div class="row">
            <div class="col-12 text-right">
              <p *ngIf="!customer">Login is required to check out.</p>
              <button
                *ngIf="currentCustomer|async"
                class="btn btn-light"
                [routerLink]="['/checkout']"
                type="button">Check out</button>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
