

<div class="container-fluid">
  <app-pagetitle title="Product Detail" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <ngb-alert
    [dismissible]="true"
    *ngIf="alert$|async"
    (close)="closeAlert()"
    type="primary">{{alertMsg}}</ngb-alert>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!--
            <div
              *ngIf="loading|async"
              class="col-12 text-center">
              <div
                  class="spinner-border text-primary m-3"
                  role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            -->

            <div class="col-xl-7">
              <div class="product-detail">
                <div class="row">

                  <div class="col-3">
                    <ul
                      ngbNav #nav="ngbNav"
                      class="nav-pills flex-column"
                      orientation="vertical"
                      [(activeId)]="activeId"
                      >

                      <ng-container
                        *ngFor="let img of (data$ | async)?.images;let i = index"
                      >
                      <li [ngbNavItem]="i">
                        <a ngbNavLink>
                          <img src="{{img.url}}" alt=""
                            class="img-fluid mx-auto d-block tab-img rounded">
                        </a>


                        <ng-template ngbNavContent active>
                          <div class="product-img">
                            <img
                              src="{{img.url}}"
                              alt=""
                              class="img-fluid mx-auto d-block"
                              data-zoom=""
                              >
                          </div>
                        </ng-template>
                      </li>
                      </ng-container>
                      <!--
                      <li [ngbNavItem]="2">
                        <a ngbNavLink>
                          <img src="assets/images/product/img-5.png" alt=""
                            class="img-fluid mx-auto d-block tab-img rounded">
                        </a>
                        <ng-template ngbNavContent>
                          <div class="product-img">
                            <img src="assets/images/product/img-5.png" alt="" class="img-fluid mx-auto d-block"
                              data-zoom="assets/images/product/img-1.png">
                          </div>
                        </ng-template>
                      </li>

                      <li [ngbNavItem]="3">
                        <a ngbNavLink>
                          <img src="assets/images/product/img-3.png" alt=""
                            class="img-fluid mx-auto d-block tab-img rounded">
                        </a>
                        <ng-template ngbNavContent>
                          <div class="product-img">
                            <img src="assets/images/product/img-3.png" alt="" class="img-fluid mx-auto d-block"
                              data-zoom="assets/images/product/img-1.png">
                          </div>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="4">
                        <a ngbNavLink>
                          <img src="assets/images/product/img-4.png" alt=""
                            class="img-fluid mx-auto d-block tab-img rounded">
                        </a>
                        <ng-template ngbNavContent>
                          <div class="product-img">
                            <img src="assets/images/product/img-4.png" alt="" class="img-fluid mx-auto d-block"
                              data-zoom="assets/images/product/img-1.png">
                          </div>
                        </ng-template>
                      </li>
                      -->
                    </ul>
                  </div>
                  <div class="col-md-8 col-9">
                    <div [ngbNavOutlet]="nav"></div>
                  </div>
                </div>
              </div>
              <!-- end product img -->
            </div>



            <div class="col-xl-5">
              <div class="mt-4 mt-xl-3">
                <a href="#" class="text-primary">{{ (data$|async)?.productType }}</a>
                <h5 class="mt-1 mb-3">{{ (data$|async)?.title}}</h5>

                <div
                  *ngIf="(data$|async)?.reviews.length > 0"
                  class="d-inline-flex">
                  <div class="text-muted mr-3">
                    <span class="mdi mdi-star text-warning"></span>
                    <span class="mdi mdi-star text-warning"></span>
                    <span class="mdi mdi-star text-warning"></span>
                    <span class="mdi mdi-star text-warning"></span>
                    <span class="mdi mdi-star"></span>
                  </div>
                  <div class="text-muted">( {{(data$|async)?.reviews.length}} )</div>
                </div>



                <div class="row mt-2">
                  <div class="col-6">
                  <select
                    class="custom-select"
                    [(ngModel)]='selectedOptionId'>
                    <option
                      *ngFor="let variant of (data$|async)?.variants"
                      [value]="variant.id">{{variant.title}} : ${{variant.priceV2}}
                    </option>


                  </select>
                  </div>
                </div>


                <div class="row mt-2">
                  <div class="col-6">
                    <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                      <label class='form-control'>Quantity</label>
                      <input type="number" [(ngModel)]="orderQty" name="demo_vertical" class="form-control text-right">
                    </div>
                   </div>
                   <div class="col-6">
                     <button
                       class="btn btn-light"
                       (click)="addToCart()"
                       type="button">Add to cart</button>

                   </div>
                </div>




                <!--
                <p class="mt-3">To achieve this, it would be necessary to have uniform pronunciation</p>
                -->
                <hr class="my-4">

                <div class="row">

                  <div class="col-md-6">
                    <div>

                      <!--
                      <h5 class="font-size-14"><i class="mdi mdi-location"></i> Delivery location</h5>
                      <div class="form-inline">

                        <div class="input-group mb-3">
                          <input type="text" class="form-control" placeholder="Enter Delivery pincode ">
                          <div class="input-group-append">
                            <button class="btn btn-light" type="button">Check</button>
                          </div>
                        </div>
                      </div>
                      -->


                      <!--
                      <ng-container
                        *ngIf="(data$|async)?.variants.length > 1"
                      >
                        <h5 class="font-size-14">Options :</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li *ngFor="let variant of (data$|async)?.variants">
                            <i class="mdi mdi-circle-medium mr-1 align-middle"></i>{{variant.title}}</li>
                        </ul>
                    </ng-container>
                    -->
                    </div>
                  </div>

                  <div>
                    <h5 class="font-size-14">Services :</h5>
                    <ul class="list-unstyled product-desc-list">
                      <li><i class="mdi mdi-sync text-primary mr-1 align-middle font-size-16"></i> 10 Days Replacement
                      </li>
                      <li><i class="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-16"></i> Cash
                        on Delivery available</li>
                    </ul>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">


                    <!--
                    <div class="product-color mt-3">
                      <h5 class="font-size-14">Color :</h5>
                      <a href="#" class="active" *ngFor="let i of productDetail[0].colorVariant">
                        <div class="product-color-item">
                          <img src="{{i.value}}" alt="" class="avatar-md">
                        </div>
                        <p>aaaa</p>
                      </a>
                    </div>
                    -->
                  </div>
                  <!--

                  <div class="col-md-6">
                    <div class="product-color mt-3">
                      <h5 class="font-size-14">Options :</h5>


                      <a href="#" class="active">
                        <div class="product-color-item">
                          <div class="avatar-xs">
                            <span class="avatar-title bg-transparent text-body">S</span>
                          </div>
                        </div>

                      </a>
                      <a href="#">
                        <div class="product-color-item">
                          <div class="avatar-xs">
                            <span class="avatar-title bg-transparent text-body">M</span>
                          </div>
                        </div>
                      </a>
                      <a href="#">
                        <div class="product-color-item">
                          <div class="avatar-xs">
                            <span class="avatar-title bg-transparent text-body">L</span>
                          </div>
                        </div>
                      </a>
                      <a href="#">
                        <div class="product-color-item">
                          <div class="avatar-xs">
                            <span class="avatar-title bg-transparent text-body">XL</span>
                          </div>
                        </div>
                      </a>

                    </div>
                  </div>

                    -->
                </div>
              </div>
            </div>


          </div>
          <!-- end row -->

          <div class="mt-4">
            <h5 class="font-size-14 mb-3">Product description: </h5>
            <div class="product-desc">
              <ul ngbNav #productNav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>
                    <a class="nav-link" id="desc-tab" data-toggle="tab" href="#desc" role="tab">Description</a>
                  </a>
                  <ng-template ngbNavContent>
                    <div [innerHtml]="(data$|async)?.descriptionHtml"></div>




                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>
                    <a class="nav-link" id="specifi-tab" data-toggle="tab" href="#specifi" role="tab">Specifications</a>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="table-responsive">
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row" style="width: 400px;">Category</th>
                            <td>{{(data$|async)?.productType}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Vendor</th>
                            <td>{{(data$|async)?.vendor}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Weight</th>
                            <td>{{(data$|async)?.variants[0].weight}} {{(data$|async)?.variants[0].weightUnit}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="productNav" class="border border-top-0 p-4"></div>
            </div>
          </div>


          <!--
          <div
            *ngIf="loading|async"
            class="col-12 text-center">
            <div
                class="spinner-border text-primary m-3"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
          -->
          <div
            class="mt-4">
            <h5 class="font-size-14">Reviews : </h5>
            <div
              *ngIf="(data$|async)?.reviews.length > 0"
              class="d-inline-flex mb-3">
              <div class="text-muted mr-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star"></span>
              </div>
              <div class="text-muted">( {{(data$|async)?.reviews.length}} customer Review)</div>
            </div>
            <div class="border p-4 rounded">
              <div
                *ngFor="let review of (data$|async)?.reviews"
                class="media border-bottom pb-3 mb-3">
                <div class="media-body">
                  <h5 class="text-muted mb-2">{{review.title}}</h5>

                  <p class="text-muted mb-2">{{review.content}}</p>
                  <h6 class="font-size-15 mb-3">{{review.writer}}</h6>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-thumb-up align-middle mr-1"></i> Like</a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-message-text align-middle mr-1"></i> Comment</a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">created : {{review.createdAt}}</p>
              </div>


              <!--
              <div class="media border-bottom py-3">
                <div class="media-body">
                  <p class="text-muted mb-2">Everyone realizes why a new common language would be desirable</p>
                  <h5 class="font-size-15 mb-3">David</h5>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-thumb-up align-middle mr-1"></i> Like</a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-message-text align-middle mr-1"></i> Comment</a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">22 Jan, 2020</p>
              </div>
              <div class="media py-3">
                <div class="media-body">
                  <p class="text-muted mb-2">If several languages coalesce, the grammar of the resulting </p>
                  <h5 class="font-size-15 mb-3">Scott</h5>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-thumb-up align-middle mr-1"></i> Like</a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#"><i class="mdi mdi-message-text align-middle mr-1"></i> Comment</a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">04 Jan, 2020</p>
              </div>
              -->

              <!-- review button here -->
              <button
                *ngIf="reviewFlag"
                class="btn btn-light mt-3"
                (click)="toggleReview()"
                type="button">Write a reveiw</button>

              <ng-container *ngIf="!reviewFlag">
                <form
                  [formGroup]="reviewForm"
                  class="mt-3">
                <div class="row">

                    <div class="form-group col-12">
                      <label for="review-writer">Name</label>
                      <input
                        formControlName="writer"
                        name="writer"
                        id="review-writer"
                        type="text"
                        class="form-control"/>
                    </div>

                    <div class="form-group col-12">
                      <label for="review-email">Email</label>
                      <input formControlName="email" name="email" id="review-email" type="text" class="form-control "/>
                    </div>

                    <div class="form-group col-12">
                      <label for="review-rating">Rating</label>

                      <div class="text-muted">
                        <span
                          *ngFor="let number of [1,2,3,4,5]"
                          class="mdi mdi-star mr-2 {{number<=rating?'text-warning':''}}"
                          (click)="starRating(number)"
                        ></span>

                      </div>

                    </div>

                    <div class="form-group col-12">
                      <label for="review-title">Review Title</label>
                      <input name="title" formControlName="title" id="review-title" type="text" class="form-control "/>
                    </div>

                    <div class="form-group col-12">
                      <label for="review-content">Body of Review (1500)</label>
                      <textarea
                        name="content"
                        formControlName="content"
                        id="review-content"
                        rows="4"
                        class="form-control"
                        ></textarea>
                    </div>
                </div>
                <div class="row">
                  <div class="col-12">
                  <button
                    class="btn btn-light mr-3"
                    (click)="writeReview()"
                    type="button">Submit a reveiw</button>

                  <button
                    class="btn btn-light"
                    (click)="resetReviewForm()"
                    type="button">Cancel</button>
                  </div>
                </div>
              </form>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="media">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Free Shipping</h5>
                  <p class="text-muted mb-0">Sed ut perspiciatis unde</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="media mt-4 mt-md-0">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-exchange-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Easy Return</h5>
                  <p class="text-muted mb-0">Neque porro quisquam est</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="media mt-4 mt-md-0">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-money-dollar-circle-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Cash on Delivery</h5>
                  <p class="text-muted mb-0">Ut enim ad minima quis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
