<div class="container-fluid">
  <app-pagetitle title="Read Email" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">

      <app-sidepanel></app-sidepanel>
      <!-- Right Sidebar -->
      <div class="email-rightbar mb-3">

        <div class="card">
          <div class="btn-toolbar p-3" role="toolbar">
            <div class="btn-group mr-2 mb-2 mb-sm-0">
              <button type="button" class="btn btn-primary"><i class="fa fa-inbox"></i></button>
              <button type="button" class="btn btn-primary"><i class="fa fa-exclamation-circle"></i></button>
              <button type="button" class="btn btn-primary"><i class="far fa-trash-alt"></i></button>
            </div>
            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-folder"></i> <i class="mdi mdi-chevron-down ml-1"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Updates</a>
                <a class="dropdown-item" href="javascript: void(0);">Social</a>
                <a class="dropdown-item" href="javascript: void(0);">Team Manage</a>
              </div>
            </div>
            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ml-1"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Updates</a>
                <a class="dropdown-item" href="javascript: void(0);">Social</a>
                <a class="dropdown-item" href="javascript: void(0);">Team Manage</a>
              </div>
            </div>

            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                More <i class="mdi mdi-dots-vertical ml-2"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Mark as Unread</a>
                <a class="dropdown-item" href="javascript: void(0);">Mark as Important</a>
                <a class="dropdown-item" href="javascript: void(0);">Add to Tasks</a>
                <a class="dropdown-item" href="javascript: void(0);">Add Star</a>
                <a class="dropdown-item" href="javascript: void(0);">Mute</a>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="media mb-4">
              <img class="d-flex mr-3 rounded-circle avatar-sm" src="assets/images/users/avatar-2.jpg"
                alt="Generic placeholder image">
              <div class="media-body">
                <h5 class="font-size-14 mt-1">Humberto D. Champion</h5>
                <small class="text-muted">support@domain.com</small>
              </div>
            </div>

            <h4 class="mt-0 font-size-16">{{emailRead[0].title}}</h4>

            <p>Dear Lorem Ipsum,</p>
            <p>{{emailRead[0].subject}}
            </p>
            <p>Sed elementum turpis eu lorem interdum, sed porttitor eros commodo. Nam eu venenatis tortor, id lacinia
              diam. Sed aliquam in dui et porta. Sed bibendum orci non tincidunt ultrices. Vivamus fringilla, mi lacinia
              dapibus condimentum, ipsum urna lacinia lacus, vel tincidunt mi nibh sit amet lorem.</p>
            <p>Sincerly,</p>
            <hr />

            <div class="row">
              <div class="col-xl-2 col-6">
                <div class="card">
                  <img class="card-img-top img-fluid" src="assets/images/small/img-3.jpg" alt="Card image cap">
                  <div class="py-2 text-center">
                    <a href="" class="font-weight-medium">Download</a>
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-6">
                <div class="card">
                  <img class="card-img-top img-fluid" src="assets/images/small/img-4.jpg" alt="Card image cap">
                  <div class="py-2 text-center">
                    <a href="" class="font-weight-medium">Download</a>
                  </div>
                </div>
              </div>
            </div>

            <a href="javascript: void(0);" class="btn btn-secondary mt-4"><i class="mdi mdi-reply"></i>
              Reply</a>
          </div>

        </div>
      </div>
      <!-- card -->
    </div>
  </div>
</div>
