import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';



interface State {
    id:number;
    url:string;
}



@Injectable({
    providedIn: 'root'
})

export class AddressService {
    private _search$ = new Subject<void>();
    private _alert$ = new BehaviorSubject<boolean>(false);
    private _data$ = new BehaviorSubject<any>(null);
    public data = new Array<any>();

    private _state: State = {
        id:0,
        url:'',
    };

    constructor(private http: HttpClient) {
        this._search$.pipe(
            debounceTime(200),
            switchMap(() => this._search())
        ).subscribe(result => {
            this._data$.next(result.data);

        });
    }

    get data$() { return this._data$.asObservable(); }
    get alert$() { return this._alert$.asObservable(); }
    get id() { return this._state.id; }
    get url() { return this._state.url; }

    set id(id: number) { this._set({ id }); }
    set url(url: string) { this._set({ url }); }

    private _set(patch: Partial<State>) {
      Object.assign(this._state, patch);
      //console.log("################ set path :", patch)
      this.fetch("");
    }


    private _search(): Observable<any> {
        let data = this.data;
        return of(
            { data }
          )
    }


    public getToken(){
      let url = `${environment.api_url}/api/address/auth`;
      this.http.get<any>(url).subscribe(
        (data)=>{
            console.log("nexlogi token check :", data);
        },
        (error) => {
          console.log("error : ", error);
        }
      )
    }

    public getDetail(id){
        //console.log("### fetch run :", this.url);

        let urlWithParam = `${environment.api_url}/api/address/${id}`;
        console.log("query url check :",urlWithParam );
        this.http.get<any>(urlWithParam).subscribe(
          (data)=>{
              console.log("add adddre detail check. :", data);
              //this.data = data;
              //this._search$.next();

          },
          (error) => {
            console.log("error : ", error);
          }
        )

    }

    public fetch(query){
        //console.log("### fetch run :", this.url);
        if(query){
          let urlWithParam = `${environment.api_url}/api/address?query=${query}`;
          console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
                console.log("add adddress check. :", data);
                this.data = data;
                this._search$.next();

            },
            (error) => {
              console.log("error : ", error);
            }
          )
        }
    }

    public create(addr){

      console.log("address create :", addr);
      let urlWithParam = `${environment.api_url}/api/address`;
      return this.http.post<any>(urlWithParam, addr);
      /*
      this.http.post<any>(urlWithParam, addr).subscribe(
        (data)=>{
            console.log("hello world :", data);
        },
        (error) => {
          console.log("error : ", error);
        }
      )
      */

    }

    public delete(id){
      let urlWithParam = `${environment.api_url}/api/address/${id}`;
      return this.http.delete<any>(urlWithParam);
      /*
      this.http.post<any>(urlWithParam, addr).subscribe(
        (data)=>{
            console.log("hello world :", data);
        },
        (error) => {
          console.log("error : ", error);
        }
      )
      */
    }
}
