import { Component   } from '@angular/core';
import {LoadingService} from './core/services/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent  {
  title = 'shopping';
  loading: LoadingService;
  constructor( private _service: LoadingService) {
    this.loading = _service;
  }

}
