<div class="container-fluid">

<!--
  <app-pagetitle [title]="title" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
-->
  <div class="row">
    <div class="col-3 d-none d-sm-block">
      <div class="card">
        <div class="card-header bg-transparent border-bottom">
          <h5 class="mb-0">Categories</h5>
        </div>

        <div class="card-body">
    <!--
          <ul class="list-unstyled categories-list mb-0">
            <li>
              <a
                href="javascript:void(0);"
                (click)="routing(null)">
                <i class="mdi mdi-circle-medium mr-1"></i>All</a>
            </li>


            <li *ngFor="let category of categories$|async">
            <a
                href="javascript:void(0);"
                (click)="routing(category.id)">
              <i class="mdi mdi-circle-medium mr-1"></i>{{category.titleEn }}</a>
            </li>


            <li *ngFor="let collection of collections$|async">
            <a
                href="javascript:void(0);"
                (click)="routing(collection.id)">
              <i class="mdi mdi-circle-medium mr-1"></i>{{collection.title }}</a>
            </li>



          </ul>


          <ngb-accordion

          [closeOthers]="true" activeIds="static-1" class="custom-accordion categories-accordion">
            <ngb-panel id="static-1" class="categories-group-card">
              <ng-template ngbPanelTitle>
                <a href="javascript: void(0);">Electronic</a>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
-->



          <ngb-accordion
          *ngFor="let category of categories$|async;let inx=index"
          [closeOthers]="true"
          activeIds="static-{{categoryId}}"
          class="custom-accordion categories-accordion">



            <ngb-panel
              id="static-{{category.id}}"
              *ngIf="category.children.length == 0"
              class="categories-group-card">
              <ng-template ngbPanelTitle>
                <a
                  href="javascript: void(0);"
                  (click)="routing(category.id, category.titleEn, category.id)"
                  >{{category.titleEn}}</a>
              </ng-template>
            </ngb-panel>


            <ngb-panel
              *ngIf="category.children.length > 0"
              id="static-{{category.id}}"
              class="categories-group-card">
              <ng-template ngbPanelTitle>
                <!--<i class="mdi mdi-desktop-classic font-size-16 align-middle mr-2"></i>-->
                {{category.titleEn}}
                <!--<i class="mdi mdi-minus float-right accor-plus-icon"></i>-->
              </ng-template>
              <ng-template
                *ngIf="category.children.length > 0"
                ngbPanelContent>
                <div>
                  <ul class="list-unstyled categories-list mb-0">
                    <li
                      *ngFor="let item of category.children"
                    ><a

                      href="javascript: void(0);"
                      (click)="routing(item.id, item.titleEn, category.id)"
                    ><i class="mdi mdi-circle-medium mr-1"></i> {{item.titleEn}}</a></li>
                  </ul>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>

<!-- Product view -->
    <div class="col-lg-9 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="row mb-2">
              <div class="col-md-6">

                <div>
                  <h5>{{title}}</h5>
                  <!--
                  <ol class="breadcrumb p-0 bg-transparent mb-2">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Fashion</a></li>
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Clothing</a></li>
                    <li class="breadcrumb-item active">T-shirts</li>
                  </ol>
                -->
                </div>

              </div>

              <div class="col-md-6">
                <div class="form-inline float-md-right">
                  <div class="search-box ml-2">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control rounded"
                        placeholder="Search..."
                        [value]="searchText"
                        (input)="searchFilter($event)">
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>


<!--
            <ul class="list-inline my-3 ecommerce-sortby-list">
              <li class="list-inline-item"><span class="font-weight-medium font-family-secondary">Sort by:</span></li>
              <li class="list-inline-item active"><a href="javascript: void(0);">Popularity</a></li>
              <li class="list-inline-item"><a href="javascript: void(0);">Newest</a></li>
              <li class="list-inline-item"><a href="javascript: void(0);">Discount</a></li>
            </ul>
-->
            <div class="row no-gutters">
              <!--
              <div
                *ngIf="loading|async"
                class="col-12 text-center">
                <div
                    class="spinner-border text-primary m-3"
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
              </div>
              -->
              <div  class="col-xl-4 col-sm-6" *ngFor="let product of data$ | async">
                <div class="product-box">
                  <div class="product-img">
                    <div
                      *ngIf="product.variants[0]?.compareAtPriceV2 != null && product.variants[0]?.compareAtPriceV2 > product.variants[0]?.priceV2"
                      class="product-ribbon badge badge-primary">
                      {{(product.variants[0]?.priceV2 - product.variants[0]?.compareAtPriceV2)/product.variants[0]?.compareAtPriceV2 | percent }}
                    </div>


                    <div class="product-like">
                      <a href="javascript: void(0);">
                        <i class="mdi mdi-heart-outline"></i>
                      </a>
                    </div>
                    <a [routerLink]="['/ecommerce/products',product.id]">
                      <img

                        src="{{product?.featuredImage?product.featuredImage.url:product.images[0].url}}"
                        alt=""
                        class="img-fluid mx-auto d-block">
                    </a>
                  </div>

                  <div class="text-center">
                    <p class="font-size-12 mb-1">{{product.productType}}</p>
                    <h5 class="font-size-15 product-name">
                      <a [routerLink]="['/ecommerce/products',product.id]" class="text-dark">{{product.title}}</a>
                    </h5>
                    <h5 class="mt-3 mb-0">
                      <span class="text-muted mr-2 font-size-15">

                        <del
                          *ngIf="product.variants[0]?.compareAtPriceV2 != null && product.variants[0]?.compareAtPriceV2 > product.variants[0]?.priceV2"
                        >$ {{product.variants[0]?.compareAtPriceV2}}</del>
                      </span>
                      ${{product.variants[0]?.priceV2}}</h5>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mt-4">
              <div class="col-lg-12">

                <ngb-pagination
                  class="pagination-rounded d-flex justify-content-center"
                  [collectionSize]="total$ | async"
                  [maxSize]="10"
                  [ellipses]="false"
                  [(page)]="service.page"
                  [pageSize]="service.pageSize">
              </ngb-pagination>

              <!--
                <ngb-pagination
                  class="pagination-rounded d-flex justify-content-center"
                  [collectionSize]="6" page="1"
                  [maxSize]="1"
                  [rotate]="true"
                  [ellipses]="false"
                  [boundaryLinks]="true">
                </ngb-pagination>
                ==>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

</div> <!-- container-fluid -->
