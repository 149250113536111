import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// tslint:disable-next-line: max-line-length
import { NgbAlertModule, NgbAccordionModule, NgbNavModule, NgbTypeaheadModule, NgbPaginationModule, NgbCollapseModule, NgbTooltipModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from 'angular-archwizard';
import { Ng5SliderModule } from 'ng5-slider';
import { UiModule } from '../../shared/ui/ui.module';

import { EcommerceRoutingModule } from './ecommerce-routing.module';

import { ProductsComponent } from './products/products.component';
import { CollectionsComponent } from './collections/collections.component';


import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CustomersComponent } from './customers/customers.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShopsComponent } from './shops/shops.component';
import { AddProductComponent } from './add-product/add-product.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    ProductsComponent,
    CollectionsComponent,
    ProductDetailComponent,
    CustomersComponent,
    CartComponent, CheckoutComponent, ShopsComponent, AddProductComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EcommerceRoutingModule,
    UiModule,
    ArchwizardModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbCollapseModule,
    Ng5SliderModule,
    NgbModalModule,
    NgbAlertModule
  ]
})
export class EcommerceModule { }
