<div class="container-fluid">
  <app-pagetitle title="Checkout" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <aw-wizard>
            <aw-wizard-step stepTitle="Receiver Info">
              <h5 class="card-title">Receiver information</h5>
              <p class="card-title-desc">Write receiver information</p>

              <form [formGroup]="form" id="form">
                <input type="hidden"  formControlName="customerId" />
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-4">
                        <label for="billing-name">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="name"
                          placeholder="Enter name">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-4">
                        <label for="billing-email-address">Email Address</label>
                        <input
                          type="email"
                          class="form-control"
                          formControlName="email"
                          placeholder="Enter email">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-4">
                        <label for="billing-phone">Phone</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="phone"
                          >
                      </div>
                    </div>

                </div>


<!--
                  <div class="form-group mb-4">
                    <label for="billing-address">Address</label>
                    <textarea class="form-control" id="billing-address" rows="3"
                      placeholder="Enter full address"></textarea>
                  </div>
-->





                </div>
              </form>
              <button type="button" awNextStep class="btn btn-primary mt-4 float-right">Next Step</button>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="Shipping Info" awOptionalStep>
              <h5 class="card-title">Shipping information</h5>
              <p class="card-title-desc">Select Shipping address</p>

              <div class="row">



                <div class="col-lg-4 col-sm-6"
                  *ngFor="let addr of customer?.addresses;let inx=index"
                >
                  <div
                    class="card border rounded shipping-address {{shippingAddrId==addr.id?'active':''}}"
                    (click)='selectShippingAddr(addr.id)'
                    >
                    <div class="card-body">
                      <a
                        class="float-right ml-1"
                        href="javascript:void(0)"
                        (click)='removeAddr(addr.id)'
                        >
                        <i class="ri-delete-bin-fill"></i>
                      </a>



                      <h5 class="font-size-14 mb-4">Address {{inx+1}}</h5>

                      <h5 class="font-size-14"></h5>
                      <p class="mb-1">{{addr.full_address}}</p>

                    </div>
                  </div>

                </div>

                <div class="col-lg-4 col-sm-6">
                  <div
                    class="card border rounded shipping-address"

                    (click)="openModal(addressModal)"
                    >
                    <div class="card-body text-center">
                      <h5 class="font-size-14 mb-4">Add</h5>
                    </div>
                  </div>

                </div>

                <!--
                <div class="col-lg-4 col-sm-6">
                  <div class="card border rounded active shipping-address">
                    <div class="card-body">
                      <a href="#" class="float-right ml-1">Edit</a>
                      <h5 class="font-size-14 mb-4">Address 1</h5>

                      <h5 class="font-size-14">Bradley McMillian</h5>
                      <p class="mb-1">109 Clarksburg Park Road Show Low, AZ 85901</p>
                      <p class="mb-0">Mo. 012-345-6789</p>
                    </div>
                  </div>

                </div>



                <div class="col-lg-4 col-sm-6">
                  <div class="card border rounded shipping-address">
                    <div class="card-body">
                      <a href="#" class="float-right ml-1">Edit</a>
                      <h5 class="font-size-14 mb-4">Address 2</h5>

                      <h5 class="font-size-14">Bradley McMillian</h5>
                      <p class="mb-1">109 Clarksburg Park Road Show Low, AZ 85901</p>
                      <p class="mb-0">Mo. 012-345-6789</p>
                    </div>
                  </div>
                </div>
                -->



              </div>
              <button type="button" awNextStep class="btn btn-primary float-right">Go to next step</button>

              <button type="button" awPreviousStep class="btn btn-primary ">Go to previous step</button>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="Payment Info">
              <h5 class="card-title">Payment information</h5>
              <p class="card-title-desc"></p>

              <div>
                <!--
                <h5 class="font-size-14">Payment method :</h5>
                -->
                <div class="row">


                  <!--
                  <div class="col-lg-12">
                    <div class="form-group mb-4">
                      <label>Billing Address</label>
                      <div class="input-group">
                      <input
                        [(ngModel)]="address"
                        [ngModelOptions]="{standalone:true}"
                        class="form-control"
                        (keyup)="addressSearch()"
                        (change)="setAddressId()"
                        list="SearchResults"
                        >


                      <datalist
                      *ngIf="!this.addressId"
                      id="SearchResults">
                        <option
                          *ngFor="let sr of addressSearchResult | async"
                          [value]="sr.full_address"
                          >{{sr.full_address}}</option>
                      </datalist>
                      <button
                        *ngIf="this.addressId"
                        type="button"
                        class="btn btn-success"
                        (click)="resetId()"
                        >reset</button>
                        </div>
                    </div>
                  </div>
                -->

                
                  <div class="col-lg-4 col-sm-6">
                    <div>
                      <label class="card-radio-label mb-3">
                        <input type="radio" name="pay-method" id="pay-methodoption1" class="card-radio-input">
                        <div class="card-radio">
                          <i class="fab fa-cc-mastercard font-size-24 align-middle mr-2"></i>
                          <span>Credit / Debit Card</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6">
                    <div>
                      <label class="card-radio-label mb-3">
                        <input type="radio" name="pay-method" id="pay-methodoption2" class="card-radio-input">
                        <div class="card-radio">
                          <i class="fab fa-cc-paypal font-size-24 align-middle mr-2"></i>
                          <span>Paypal</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6">
                    <div>
                      <label class="card-radio-label mb-3">
                        <input type="radio" name="pay-method" id="pay-methodoption3" class="card-radio-input" checked>
                        <div class="card-radio">
                          <i class="far fa-money-bill-alt font-size-24 align-middle mr-2"></i>
                          <span>Cash on Delivery</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <h5 class="my-3 font-size-14">For card Payment</h5>
                <div class="p-4 border">
                  <form>
                    <div class="form-group">
                      <label for="cardnameInput">Name on card</label>
                      <input type="text" class="form-control" id="cardnameInput" placeholder="Name on Card">
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div class="form-group mb-lg-0">
                          <label for="cardnumberInput">Card Number</label>
                          <input type="text" class="form-control" id="cardnumberInput"
                            placeholder="0000 0000 0000 0000">
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="form-group mb-lg-0">
                          <label for="expirydateInput">Expiry date</label>
                          <input type="text" class="form-control" id="expirydateInput" placeholder="MM/YY">
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6">
                        <div class="form-group mb-lg-0">
                          <label for="cvvcodeInput">CVV Code</label>
                          <input type="text" class="form-control" id="cvvcodeInput" placeholder="Enter CVV Code">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="mt-4 text-right">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-success"
                    (click)="excuteOrder()"
                    >
                    Complete order
                  </a>
                </div>
              </div>
              <button type="button" awPreviousStep class="btn btn-primary">Previous</button>
            </aw-wizard-step>
          </aw-wizard>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card checkout-order-summary">
        <div class="card-body">
          <div class="p-3 bg-light mb-4">
            <h5 class="font-size-14 mb-0">Order Summary <span class="float-right ml-2"></span></h5>
          </div>
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead>
                <tr>
                  <th class="border-top-0" style="width: 110px;" scope="col">Product</th>
                  <th class="border-top-0" scope="col">Product Desc</th>
                  <th class="border-top-0" scope="col">Price</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let line of (cartData|async)?.lines;let inx = index">


                  <th scope="row"><img src="{{ line.product.featuredImage.url }}" alt="product-img" title="product-img"
                      class="avatar-md"></th>
                  <td>
                    <h5 class="font-size-14 text-truncate"><a href="ecommerce-product-detail.html"
                        class="text-dark">{{ line.product.title }} </a></h5>
                    <p class="mb-0"><span class="font-weight-medium">{{ line.variant.title !="Default Title"?line.variant.title:'' }}</span></p>
                    <p class="text-muted mb-0">$ {{ line.variant.priceV2 }} x {{line.quantity}} </p>
                  </td>
                  <td>$ {{ (line.quantity * line.variant.priceV2) | number }}</td>

                </tr>
                <tr>
                  <td colspan="2">
                    <h6 class="m-0 text-right">Sub Total:</h6>
                  </td>
                  <td>
                    $ {{subTotal}}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="bg-soft-primary p-3 rounded">
                      <h5 class="font-size-14 text-primary mb-0"><i class="fas fa-shipping-fast mr-2"></i> Shipping
                        <span class="float-right">Free</span></h5>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <h6 class="m-0 text-right">Total:</h6>
                  </td>
                  <td>
                    $ {{total}}
                  </td>
                </tr>




              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end row -->

</div>



<!-- address model-->
<ng-template #addressModal role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">Address</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <div class="row">
        <div class="form-group col-12">
          <label>Address</label>
          <div class="input-group">
          <input
            [(ngModel)]="address"
            [ngModelOptions]="{standalone:true}"
            class="form-control"
            (keyup)="addressSearch()"
            (change)="setAddressId()"
            list="SearchResults"
            >
          <datalist
          *ngIf="!this.addressId"
          id="SearchResults">
            <option
              *ngFor="let sr of addressSearchResult | async"
              [value]="sr.full_address"
              >{{sr.full_address}}</option>
          </datalist>
          <button
            *ngIf="this.addressId"
            type="button"
            class="btn btn-success"
            (click)="resetId()"
            >reset</button>
            </div>
        </div>
    </div>
        <div class="text-right">
          <button
            type="button"
            class="btn btn-success"
            (click)="saveAddress()"
            >Save</button>
          <button type="button" class="btn btn-danger ml-1" (click)="modal('close click')">Cancel</button>
        </div>

    </div>
  </div>
</ng-template>
