import { Component, OnInit } from '@angular/core';

import { Cart } from './cart.model';
import { CartService } from './cart.service';
import { cartData } from './data';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/services/auth.service';
import { Customer } from '../../../core/models/auth.models';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [CartService,AuthenticationService]
})
/**
 * Ecommerce Cart component
 */
export class CartComponent implements OnInit {

  // breadcrumb items
  breadCrumbItems: Array<{}>;
  currentCustomer: Observable<Customer>;
  cartData: Observable<any>;
  cart:any;
  subTotal:any;
  discount:number;
  shippingCharge:number;
  total:any;
  customer:Customer;

  constructor(
    public service:CartService,
    private authService: AuthenticationService,
  ) {

    this.cartData = service.data$;
    this.cartData.subscribe((d)=>this.cart = d);
    this.currentCustomer = authService.currentCustomer;
    this.currentCustomer.subscribe((c)=>this.customer = c);

  }

  updateQuantity(target, inx){
    //console.log("target check :", target.value);
    //console.log("inx check :", inx);
    if(target.value == 0){
        this.removeItem(inx);
    }else{
      this.service.updateCart(this.cart);
    }

  }

  ngOnInit(): void {
    //this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Cart', active: true }];
    /**
     * fetch data
     */
    //this._fetchData();

    if(this.customer){
      this.service.fetchWithCustomer(this.customer.id);
    }else{
      this.service.fetch();
    }





    this.cartData.subscribe((data)=>{
      let total = 0;
      data?.lines.forEach(line => {
        total += Number(line.quantity) * Number(line.variant.priceV2);

      });

      this.subTotal = total.toFixed(2);
      this.discount = 0;
      this.shippingCharge = 0;
      this.total = (this.subTotal - this.discount + this.shippingCharge).toFixed(2);
    })
  }



  removeItem(inx){
    //console.log("remove check before :", this.cart.lines);
    this.cart.lines.splice(inx, 1);
    this.service.updateCart(this.cart);
    //console.log("remove check after :", this.cart.lines);

  }
}
