<div class="container-fluid">
  <app-pagetitle title="Inbox" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">
      <app-sidepanel></app-sidepanel>
      <div class="email-rightbar mb-3">

        <div class="card">
          <div class="btn-toolbar p-3" role="toolbar">
            <div class="btn-group mr-2 mb-2 mb-sm-0">
              <button type="button" class="btn btn-primary"><i class="fa fa-inbox"></i></button>
              <button type="button" class="btn btn-primary"><i class="fa fa-exclamation-circle"></i></button>
              <button type="button" class="btn btn-primary" (click)="confirm()"><i
                  class="far fa-trash-alt"></i></button>
            </div>
            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-folder"></i> <i class="mdi mdi-chevron-down ml-1"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Updates</a>
                <a class="dropdown-item" href="javascript: void(0);">Social</a>
                <a class="dropdown-item" href="javascript: void(0);">Team Manage</a>
              </div>
            </div>
            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ml-1"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Updates</a>
                <a class="dropdown-item" href="javascript: void(0);">Social</a>
                <a class="dropdown-item" href="javascript: void(0);">Team Manage</a>
              </div>
            </div>

            <div class="btn-group mr-2 mb-2 mb-sm-0" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                aria-expanded="false">
                More <i class="mdi mdi-dots-vertical ml-2"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" (click)="markUnread()" href="javascript: void(0);">Mark as Unread</a>
                <a class="dropdown-item" href="javascript: void(0);">Mark as Important</a>
                <a class="dropdown-item" href="javascript: void(0);">Add to Tasks</a>
                <a class="dropdown-item" href="javascript: void(0);">Add Star</a>
                <a class="dropdown-item" href="javascript: void(0);">Mute</a>
              </div>
            </div>
          </div>

          <ul class="message-list">
            <li class="unread" *ngFor="let email of emailData | slice:0:15; let i = index; "
              [ngClass]="{ 'unread': email.unread === true }">
              <div class="col-mail col-mail-1">
                <div class="checkbox-wrapper-mail">
                  <input type="checkbox" id="chk-{{i}}" (change)="selectMail($event,email.id)" />
                  <label for="chk-{{i}}"></label>
                </div>
                <a [routerLink]="['../read',email.id]" class="title">{{email.title}}</a>
                <span class="star-toggle far fa-star"></span>
              </div>
              <div class="col-mail col-mail-2">
                <a [routerLink]="['../read',email.id]" class="subject">{{email.subject}}</a>
                <div class="date">{{email.date}}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="row justify-content-md-between align-items-md-center">
          <div class="col-7">
            Showing {{startIndex}} - {{endIndex}} of {{totalRecords}}
          </div> <!-- end col-->
          <div class="col-5">
            <div class="text-md-right float-md-right mt-2">
              <ngb-pagination class="pagination-rounded" [collectionSize]="totalRecords"
                (pageChange)="onPageChange($event)" [(page)]="page" [pageSize]="pageSize">
              </ngb-pagination>
              <!--Pagination-->
            </div>
          </div> <!-- end col-->
        </div>
      </div>
    </div>
  </div>
</div>
