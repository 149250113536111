import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

interface State {
    id:number;
    url:string;
}



@Injectable({
    providedIn: 'root'
})

export class ProductDetailService {
    private _search$ = new Subject<void>();
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _data$ = new BehaviorSubject<any>(null);
    public data = new Array<any>();

    private _state: State = {
        id:0,
        url:'',
    };

    constructor(private http: HttpClient) {
        this._search$.pipe(
            tap(() => this._loading$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this._loading$.next(false))
        ).subscribe(result => {
            this._data$.next(result.data);

        });
    }

    get data$() { return this._data$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get id() { return this._state.id; }
    get url() { return this._state.url; }

    set id(id: number) { this._set({ id }); }
    set url(url: string) { this._set({ url }); }

    private _set(patch: Partial<State>) {
      Object.assign(this._state, patch);
      //console.log("################ set path :", patch)
      this.fetch();
    }


    private _search(): Observable<any> {
        let data = this.data;
        return of(
            { data }
          )
    }

    public uploadReview(form){
        this._loading$.next(true);
        let urlWithParam = `${environment.api_url}/product/review`;
        //console.log("query url check :",urlWithParam );
        this.http.post<any>(urlWithParam, form).subscribe(
          (data)=>{
              //console.log("hello world :", data);
              this.data = data;
              this._search$.next();
          },
          (error) => {
            console.log("error : ", error);
          }
        )
    }

    public fetch(){
        //console.log("### fetch run :", this.url);
        if(this.url){
          this._loading$.next(true);
          let urlWithParam = `${environment.api_url}/api/${this.url}`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
                //console.log("hello world :", data);
                this.data = data;
                this._search$.next();

            },
            (error) => {
              console.log("error : ", error);
            }
          )
        }
    }

}
