<div class="email-leftbar card">
  <button type="button" class="btn btn-danger btn-block" data-toggle="modal" data-target="#composemodal"
    (click)="open(content)">
    Compose
  </button>
  <div class="mail-list mt-4">
    <a [routerLink]="['/email/inbox']" class="active"><i class="mdi mdi-email-outline mr-2"></i> Inbox <span
        class="ml-1 float-right">(18)</span></a>
    <a [routerLink]="['/email/inbox']"><i class="mdi mdi-star-outline mr-2"></i>Starred</a>
    <a [routerLink]="['/email/inbox']"><i class="mdi mdi-diamond-stone mr-2"></i>Important</a>
    <a [routerLink]="['/email/inbox']"><i class="mdi mdi-file-outline mr-2"></i>Draft</a>
    <a [routerLink]="['/email/inbox']"><i class="mdi mdi-email-check-outline mr-2"></i>Sent Mail</a>
    <a [routerLink]="['/email/inbox']"><i class="mdi mdi-trash-can-outline mr-2"></i>Trash</a>
  </div>

  <h6 class="mt-4">Labels</h6>

  <div class="mail-list mt-1">
    <a href="javascript: void(0);"><span class="mdi mdi-arrow-right-drop-circle text-info float-right"></span>Theme
      Support</a>
    <a href="javascript: void(0);"><span
        class="mdi mdi-arrow-right-drop-circle text-warning float-right"></span>Freelance</a>
    <a href="javascript: void(0);"><span
        class="mdi mdi-arrow-right-drop-circle text-primary float-right"></span>Social</a>
    <a href="javascript: void(0);"><span
        class="mdi mdi-arrow-right-drop-circle text-danger float-right"></span>Friends</a>
    <a href="javascript: void(0);"><span
        class="mdi mdi-arrow-right-drop-circle text-success float-right"></span>Family</a>
  </div>

  <h6 class="mt-4">Chat</h6>

  <div class="mt-2">
    <a href="javascript: void(0);" class="media">
      <img class="d-flex mr-3 rounded-circle" src="assets/images/users/avatar-2.jpg" alt="Generic placeholder image"
        height="36">
      <div class="media-body chat-user-box">
        <p class="user-title m-0">Scott Median</p>
        <p class="text-muted">Hello</p>
      </div>
    </a>

    <a href="javascript: void(0);" class="media">
      <img class="d-flex mr-3 rounded-circle" src="assets/images/users/avatar-3.jpg" alt="Generic placeholder image"
        height="36">
      <div class="media-body chat-user-box">
        <p class="user-title m-0">Julian Rosa</p>
        <p class="text-muted">What about our next..</p>
      </div>
    </a>

    <a href="javascript: void(0);" class="media">
      <img class="d-flex mr-3 rounded-circle" src="assets/images/users/avatar-4.jpg" alt="Generic placeholder image"
        height="36">
      <div class="media-body chat-user-box">
        <p class="user-title m-0">David Medina</p>
        <p class="text-muted">Yeah everything is fine</p>
      </div>
    </a>

    <a href="javascript: void(0);" class="media">
      <img class="d-flex mr-3 rounded-circle" src="assets/images/users/avatar-6.jpg" alt="Generic placeholder image"
        height="36">
      <div class="media-body chat-user-box">
        <p class="user-title m-0">Jay Baker</p>
        <p class="text-muted">Wow that's great</p>
      </div>
    </a>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="composemodalTitle">New Message</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <input type="email" class="form-control" placeholder="To">
        </div>

        <div class="form-group">
          <input type="text" class="form-control" placeholder="Subject">
        </div>
        <div class="form-group">
          <!-- Editor -->
          <ckeditor [editor]="Editor" data="<p>Content of the editor.</p>"></ckeditor>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
      <button type="button" class="btn btn-primary">Send <i class="fab fa-telegram-plane ml-1"></i></button>
    </div>
  </ng-template>
</div>
