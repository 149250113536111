import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../../core/services/language.service';
import { Customer } from '../../../core/models/auth.models';
import { CartService } from '../../../pages/ecommerce/cart/cart.service';
import { Cart } from '../../../pages/ecommerce/cart/cart.model';
import { EventService } from '../../../core/services/event.service';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss'],
  providers: [CartService,AuthenticationService]
})
export class HorizontaltopbarComponent implements OnInit {


  public currentCustomer: Observable<Customer>;
  configData: any;

  element: any;
  cookieValue;


  valueset: string;
  isLogin:boolean;
  customer:Customer;
  cartCount :number;


  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  constructor(@Inject(DOCUMENT)
    private document: any,
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    public cookiesService: CookieService,
    public cartService:CartService,
    private eventService: EventService
  ) {

      this.currentCustomer = authService.currentCustomer;
      this.currentCustomer.subscribe((d)=>this.customer = d);
  }

  ngOnInit(): void {
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);

    if(this.customer){
      this.cartService.fetchWithCustomer(this.customer.id);
    }else{
      this.cartService.fetch();
    }

    this.eventService.subscribe('changeCartCount', (count) => {

      this.cartCount = count;
      //this.layoutType = layout;
    });
  /*

    this.authService.currentCustomerValue.subscribe(
      (customer)=>{
        console.log("############# customer check :", customer);
        this.isLogin = true;
      },
      (error)=>{
        this.isLogin = false;
      }
  )


    if(this.currentCustomer){
      this.isLogin = true;
    }else{
      this.isLogin = false;
    }
*/


  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {


    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Logout the user
   */
  logout() {
    console.log("### logout check ");
    this.authService.customerLogout();
    //this.router.navigate(['/']);
  }

}
