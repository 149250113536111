import { Component, OnInit } from '@angular/core';
import { Cart } from '../cart/cart.model';
import { CartService } from '../cart/cart.service';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/services/auth.service';

import { PaymentService } from '../../../core/services/payment.service';
import { CustomerProfileService } from '../../../core/services/customer.service';
import { Customer } from '../../../core/models/auth.models';
import {  Payment,PaymentResponse } from '../../../core/models/payment.model';
import {Address} from '../../../model/address.model';
import {Order} from '../../../model/order.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../../core/services/order.service';
import { AddressService } from '../../../core/services/address.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [CartService,AuthenticationService,AddressService,OrderService,CustomerProfileService]
})
export class CheckoutComponent implements OnInit {

  // breadcrumb items
  public form: FormGroup;
  breadCrumbItems: Array<{}>;
  currentCustomer: Observable<Customer>;
  cartData: Observable<any>;
  cart:any;
  subTotal:any;
  discount:number;
  shippingCharge:number;
  total:any;
  customer:Customer;

  customer$: Observable<any>;

  address:string;
  addressSearchResult:Observable<any>;
  addressId:number;
  addresses:Array<Address>;

  shippingAddrId:number;
  modalReference = null;


  constructor(
    private cartService:CartService,
    private authService: AuthenticationService,
    private addressService:AddressService,
    private formBuilder: FormBuilder,
    private orderService:OrderService,
    private customerService:CustomerProfileService,
    private modalService: NgbModal,
    private router: Router,
    private paymentService:PaymentService,
    private route: ActivatedRoute,

  ) {


    this.shippingAddrId = 0;
    this.customer$ = customerService.data$;
    this.address = "";
    this.addressSearchResult = addressService.data$;
    this.addressSearchResult.subscribe((data)=>this.addresses = data);
    this.cartData = cartService.data$;
    this.cartData.subscribe(
      (d)=>{
        console.log("### cart Datat check :", d);
    this.cart = d;
    }
    );
    this.currentCustomer = authService.currentCustomer;
    this.form = formBuilder.group({
      name: formBuilder.control(null, []),
      email: formBuilder.control(null, []),
      phone: formBuilder.control(null, []),
      customerId: formBuilder.control(null, []),

    });

  }

  ngOnInit(): void {
    //this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Checkout', active: true }];
    this.route.params.subscribe(p => {
        if(p){
          console.log("### rouote params check :", p);
          if(p.payment == 'success'){
            //payment token chec
            this.route.queryParams.subscribe(qParams => {
              console.log("payment success token :", qParams.token.trim());

              let ref = {};
              ref["TransactionRefNo"] = qParams.token.trim();

              this.orderService.find(ref).subscribe((order)=>{
                order.items.forEach(item => {
                  item.status = "ORDER";
                });
                this.orderService.update(order).subscribe((result)=>{
                  let cart = new Cart();
                  this.cartService.getCart(cart);
                  this.router.navigate(['/mypage']);
                });
              })
              //let gatewayName = `https://txn.apac.paywithpoli.com/?Token=${qParams.token}`;
              //find order by gatewayName...
            });
          }else if(p.payment == 'fail'){
            alert("#### Payment process fail, try again.");
          }
        }
    })

    this.customer$.subscribe((c)=>{
      console.log("#### CUSTOMER check 2:", c);
      if(c){
        this.customer = c;
        if(c.addresses.length >0){
          if(this.shippingAddrId == 0){
            this.shippingAddrId = c.addresses[0].id;
          }
        }

        this.form.setValue({
          name:c.displayName,
          email:c.email,
          phone:c.phone,
          customerId:c.id
        })
      }

    })

    this.currentCustomer.subscribe((c)=>{
      console.log("#### current Customer check :", c);
      this.customerService.url = `customer/${c.id}`;
    });

    if(this.customer){
      this.cartService.fetchWithCustomer(this.customer.id);
    }else{
      this.cartService.fetch();
    }





    this.cartData.subscribe((data)=>{
      let total = 0;
      data?.lines.forEach(line => {
        total += Number(line.quantity) * Number(line.variant.priceV2);

      });

      this.subTotal = total.toFixed(2);
      this.discount = 0;
      this.shippingCharge = 0;
      this.total = (this.subTotal - this.discount + this.shippingCharge).toFixed(2);
    })
  }


  addressSearch(){

    if(!this.addressId && this.address.length > 4) {
      console.log("address searc keyword :", this.address);

      this.addressService.fetch(this.address);
    }

  }

  setAddressId(){

    console.log("#### setaddressId check :", this.address);

    this.addresses.forEach(addr => {
      //console.log("#### addr check point :", addr);

      if(addr.full_address == this.address){
        this.addressId = addr.address_id;
      }
    });
    /*
    this.addressId = id;
    this.addressService.getDetail(this.addressId);
    console.log("######### addressId check :", this.addressId);

    */
  }


  removeAddr(id){
    console.log("remove addr :", id);
    this.addressService.delete(id).subscribe(
      (data)=>{
          console.log("add adddress check. :", data);
          //this.service.id = this.id;
          this.customerService.url = `customer/${this.customer.id}`;
      }
    );
  }


  selectShippingAddr(id){
    console.log("shipping arr selected :", id);
    this.shippingAddrId = id;
  }

  openModal(content: any) {
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  saveAddress(){
    let addr = new Address();
    addr.full_address= this.address;
    addr.address_id = this.addressId;
    addr.customerId = this.customer.id;
    console.log("### save address check :", addr);


    this.addressService.create(addr).subscribe(
      (data)=>{
          console.log("add adddress check. :", data);
          this.customerService.url = `customer/${this.customer.id}`;

      }
    );
    this.modalReference.close();
  }



  excuteOrder(){
    let order = this.form.value;
      this.customer.addresses.forEach(addr => {
      if(addr.id == this.shippingAddrId){
        order.shippingAddressId = addr.address_id;
        order.shippingAddress = addr.full_address;
      }
    });

    order.itemAmount = Number(this.subTotal);
    order.totalAmount = Number(this.total);
    order.shippingAmount = this.shippingCharge;
    order.cartId = this.cart.id;
    console.log("order excute :", order);
    this.orderService.create(order).subscribe((data)=>{

      console.log("### check out response :", data);
      let payment = new Payment();
      payment.MerchantReference = data.id;
      payment.Amount = data.totalAmount;

      this.paymentService.initPayment(payment).subscribe(
        (response)=>{
          console.log("###### payment response check :", response);
          //need update payment..

          if(response?.success){

            let token = response.navigateURL.split("=")[1].trim();
            //data.paymentGatewayNames = response.navigateURL;
            //data.paymentGatewayNames =  encodeURIComponent(token);
            data.paymentGatewayNames =  decodeURIComponent(token);

            //data.paymentGatewayNames =  token;
            data.paymentTransactionRefNo = response.transactionRefNo;

            this.orderService.update(data).subscribe((updatedOrder)=>{
              console.log("#### updated order check :", updatedOrder);
              window.location.href=response.navigateURL;
            })


          }else{
            alert("#### Payment gateway error." + response.errorMessage);
          }
/*
          if(response?.navigateURL){

            this.router.parseUrl(response.navigateURL).subscribe(
              (url)=>{
                console.log("#### parsed url check :", url);
                console.log("#### parsed url.queryParams check :", url.queryParams);

            })

            console.log("##################### navigateURL parse :", this.router.parseUrl(response.navigateURL));
            console.log("##################### navigateURL parse :", this.router.parseUrl(response.navigateURL).queryParams);
            console.log("##################### navigateURL parse :", this.router.parseUrl(response.navigateURL).queryParams.Token);

            this.router.parseUrl(response.navigateURL).queryParams.subscribe(qParams => {
              console.log("payment success token :", qParams.token);

            });
            */

        })


    });
  }

  addCustomerAddr(){

  }

}
