<div class="wrapper">

  <div
    *ngIf="loading.isLoading | async"
    class="overlay">


      <div class="spinner-wrapper">
            <div id="loader">
                <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="loading"></div>
            </div>
      </div>
  </div>
  <router-outlet>
  </router-outlet>
</div>
