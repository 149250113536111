import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Cart } from './cart.model';
import { EventService } from '../../../core/services/event.service';
interface State {
    id:number;
    url:string;
    count:number;
}



@Injectable({
    providedIn: 'root'
})

export class CartService {
    private _search$ = new Subject<void>();
    private _alert$ = new BehaviorSubject<boolean>(false);
    private _data$ = new BehaviorSubject<Cart>(null);
    public data = new Cart();


    private _state: State = {
        id:0,
        url:'',
        count:0
    };

    constructor(
      private http: HttpClient,
      private eventService: EventService) {
        this._search$.pipe(
            debounceTime(200),
            switchMap(() => this._search())
        ).subscribe(result => {

            this.eventService.broadcast('changeCartCount', result.data?.lines.length);
            this._data$.next(result.data);
            this._alert$.next(true);

        });
    }

    get data$() { return this._data$.asObservable(); }
    get id() { return this._state.id; }
    get url() { return this._state.url; }
    get count() { return this._state.count; }
    get alert$() { return this._alert$.asObservable(); }
    set id(id: number) { this._set({ id }); }
    set url(url: string) { this._set({ url }); }
    set count(count: number) { this._set({ count }); }

    private _set(patch: Partial<State>) {
      Object.assign(this._state, patch);
      //console.log("################ set path :", patch)
      this.fetch();
    }


    public setAlert(flag){
      this._alert$.next(flag);
    }

    private _search(): Observable<any> {
        let data = this.data;
        return of(
            { data }
          )
    }

    public addToCart(item){
      let cart;
      let lines;

      if(localStorage.getItem('cart')){
        cart = JSON.parse(localStorage.getItem('cart'));

        if(!cart.id){
          localStorage.setItem('cart','');
          this.addToCart(item);
        }else{
          if(cart?.lines){
            lines = cart.lines;
          }else{
            lines = [];
          }
          lines.push(item);
          this.updateCart(cart);
        }
      }else{
        cart = new Cart();
        lines = [];
        lines.push(item);
        cart.lines = lines;
        this.getCart(cart);
      }
    }


    public updateCart(cart){
      let urlWithParam = `${environment.api_url}/api/cart`;
      //console.log("###### update cart call :", cart);
      this.http.put<any>(urlWithParam, cart).subscribe(
        (data)=>{
            //console.log("hello world update cart:", data);
            localStorage.setItem('cart', JSON.stringify(data));
            this.data = data;
            this._search$.next();
        },
        (error) => {
          console.log("error : ", error);
        }
      )
    }



    public getCart(cart){
      let urlWithParam = `${environment.api_url}/api/cart`;
      //let cart = new Cart();
      //console.log("######### getCart Call :", JSON.stringify(cart));



      this.http.post<any>(urlWithParam, cart).subscribe(
        (data)=>{

          //localStorage.setItem('cart', JSON.stringify(data));
          //console.log("hello world :", data);


            if(localStorage.getItem('cart')){
              let cart = JSON.parse(localStorage.getItem('cart'));

              if(!data.customerId){
                cart.lines.forEach(line => {
                  data.lines.push(line);
                });
                this.updateCart(data);
              }else{
                localStorage.setItem('cart', JSON.stringify(data));
                this.data = data;
                this._search$.next();
              }
            }else{
              localStorage.setItem('cart', JSON.stringify(data));
              this.data = data;
              this._search$.next();
            }
            

        },
        (error) => {
          console.log("error : ", error);
        }
      )
    }

    public fetchWithCustomer(id){
      console.log("######### fetch cart customer id check :", id);
      if(localStorage.getItem('cart')){
        let cart = JSON.parse(localStorage.getItem('cart'));
        if(cart.customerId == id){
          this.data = cart;



          this._search$.next();
        }else{
          let cart = new Cart();
          cart.customerId = id;
          this.getCart(cart);
        }
      }else{
          let cart = new Cart();
          cart.customerId = id;
          this.getCart(cart);
      }
    }

    public fetch(){

        if(localStorage.getItem('cart')){
          let cart = JSON.parse(localStorage.getItem('cart'));
          let urlWithParam = `${environment.api_url}/api/cart/${cart.id}`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
                localStorage.setItem('cart', JSON.stringify(data));
                this.data = data;
                this._search$.next();

            },
            (error) => {
              console.log("error : ", error);
            }
          )
        }else{
            let cart = new Cart();
            this.getCart(cart);
        }
    }
}
