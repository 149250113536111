export class Address {
  id?:number;
  address1?:string;
  address2?:string;
  city?:string;
  company?:string;
  coordinatesValidated?:boolean;
  country?:string;
  firstName?:string;
  lastName?:string;
  formattedArea?:string;
  latitude?:number;
  longitude?:number;

  name?:string;
  phone?:string;
  provinceCode?:string;
  zip?:string;
  customerId?:number;
  updatedAt?:string;
  createdAt?:string;
  isActive?:boolean;
  isDefault?:boolean;

  full_address?:string;
  address_id?:number;
}
