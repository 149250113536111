import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize,catchError, finalize, map,debounceTime } from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';


@Injectable()
export class BackendInterceptor implements HttpInterceptor {

    constructor( private _loading: LoadingService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return of(null).pipe(
        debounceTime(200),
        mergeMap(() => {
        this._loading.setLoading(true, request.url);
        return next.handle(request).pipe(catchError((err) => {
          //need redirect to error page..
          //console.log("##### error check ", err);
          this._loading.setLoading(false, request.url);
          return err;
        })).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
         if (evt instanceof HttpResponse) {
           this._loading.setLoading(false, request.url);
         }
         return evt;
        }));
      }))
    }
}
