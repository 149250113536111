import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { customersData } from './data';
import { OrderService } from '../../../core/services/order.service';
import { AuthenticationService } from '../../../core/services/auth.service';
import { Customers } from './customers.model';

import { Customer } from '../../../core/models/auth.models';


@Component({
  selector: 'app-customers-mypage',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [OrderService]
})
export class CustomersComponent implements OnInit {

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  submitted: boolean;

  customersData: Customers[];
  validationform: FormGroup;
  data$: Observable<any>;
  total$: Observable<number>;
  customer:Customer;


  constructor(
    public service: OrderService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {


    authService.currentCustomer.subscribe((c)=>this.customer = c);


    this.data$ = service.tables$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Profile' }, { label: 'Orders', active: true }];

    if(this.customer){

    }
    this.service.url = `order/customer/${this.customer.id}`;
    window.scrollTo(0, 0);


    /*
    this.validationform = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      balance: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      date: ['', [Validators.required]],
    });
    */

    //this._fetchData();
  }

  private _fetchData() {
    this.customersData = customersData;
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
  /**
   * Modal Open
   * @param content modal content
   */
  openModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * save the contacts data
   */


   confirmDelivery(order){
      order.status = 'DELIVERED';
      this.service.updateOrderItemStatus(order);

   }

  saveData() {
    const name = this.validationform.get('name').value;
    const phone = this.validationform.get('phone').value;
    const balance = this.validationform.get('balance').value;
    const email = this.validationform.get('email').value;
    const date = this.validationform.get('date').value;
    if (this.validationform.valid) {
      this.customersData.push({
        name,
        phone,
        balance,
        email,
        date
      });
      this.validationform = this.formBuilder.group({
        name: '',
        phone: '',
        balance: '',
        email: '',
        date: ''
      });
      this.modalService.dismissAll();
    }
    this.submitted = true;
  }

}
