import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
        {
            id: 28,
            label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
            link: '/',

        },
        {
            id: 32,
            label: 'MENUITEMS.ECOMMERCE.LIST.CART',
            link: '/ecommerce/cart',


        },
        {
            id: 33,
            label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
            link: '/ecommerce/checkout',


        },
    ];
