import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          // add authorization header with jwt token if available
          const currentUser = this.authenticationService.currentUserValue;
          let token = currentUser?.token;
          if (currentUser && currentUser.token) {
              //console.log("################### REQUEST TOKEN CHEK :", token);
              request = request.clone({
                  setHeaders: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`
                  }
              });
          }else{
              //console.log("################### REQUEST TOKEN CHEK IS NULL");
          }
        return next.handle(request);
    }
}
