import { CartItem } from './cart.item.model';

export class Cart {

    id:number;
    customerId:number;
    discountCode:string;
  	note:string;
  	totalQuantity:number;
  	createdAt:string;
  	updatedAt:string;
  	isActive:boolean;
    lines:Array<CartItem>;

    /*
    image: string;
    name: string;
    color: string;
    price: string;
    quantity: number;
    total: string;
    */
}
