import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
//import { Applicant, Applicants } from './model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
//const url = `${environment.api_url}/api/basic`;

interface State {
    id:number;
    url:string;
    page: number;
    pageSize: number;
    searchTerm: string;
    startIndex: number;
    endIndex: number;
    totalRecords: number;
}


/**
 * Table Data Match with Search input
 * @param tables Table field value fetch
 * @param term Search the value
 */


@Injectable({
    providedIn: 'root'
})
export class ProductsService {





    private _search$ = new Subject<void>();
    private _list$ = new BehaviorSubject<any[]>([]);

    private _collections$ = new BehaviorSubject<any[]>([]);

    private _categories$ = new BehaviorSubject<any[]>([]);

    private _tableButtons$ = new BehaviorSubject<any[]>([]);

    private _total$ = new BehaviorSubject<number>(0);
    public listData = new Array<any>();




    private _state: State = {
        id:0,
        url:'',
        page: 1,
        pageSize: 12,
        searchTerm: '',
        startIndex: 1,
        endIndex: 10,
        totalRecords: 0
    };

    constructor(private http: HttpClient) {
        this._search$.pipe(
            debounceTime(200),
            switchMap(() => this._search())
        ).subscribe(result => {
            this._list$.next(result.list);
            this._total$.next(result.total);
            //this._tableHeaders.next(this.tableHeaders);
        });
        //this._search$.next();
        this.fetch();
    }
    /**
     * Returns the value
     */


    get tables$() { return this._list$.asObservable(); }
    get collections$() { return this._collections$.asObservable(); }
    get categories$() { return this._categories$.asObservable(); }

    get tableButtons$() { return this._tableButtons$.asObservable(); }

    get total$() { return this._total$.asObservable(); }


    get id() { return this._state.id; }
    get url() { return this._state.url; }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get startIndex() { return this._state.startIndex; }
    get endIndex() { return this._state.endIndex; }
    get totalRecords() { return this._state.totalRecords; }

    /**
     * set the value
     */
    set id(id: number) { this._set({ id }); }
    set url(url: string) { this._set({ url }); }
    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set startIndex(startIndex: number) { this._set({ startIndex }); }
    set endIndex(endIndex: number) { this._set({ endIndex }); }
    set totalRecords(totalRecords: number) { this._set({ totalRecords }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        //console.log("################ set path :", patch)
        if(patch.page ||
          patch.pageSize ||
          patch.searchTerm ||
          patch.url
        ){
          console.log("set fetch run..", patch);
          this.fetch();
          this.getCollections();
          this.getCategories();
        }else if(patch.id){
          console.log("set fetch run..", patch);
          this._state.url = `product/collection/${patch.id}`;
          this.fetch();
          this.getCollections();
          this.getCategories();
        }

    }
    private _search(): Observable<any> {
        const { pageSize, page, searchTerm } = this._state;
        let list = this.listData;
        let total = this.totalRecords;

        this._state.startIndex = (page - 1) * this.pageSize + 1;
        this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
        if (this.endIndex > this.totalRecords) {
            this.endIndex = this.totalRecords;
        }
    return of(
            { list, total }
          )
    }

    public fetch(){
        //console.log("### fetch run :", this.url);
        if(this.url){

          let urlWithParam = `${environment.api_url}/api/${this.url}?page=${this._state.page-1}&size=${this._state.pageSize}&shopStatus=active&status=active&${this._state.searchTerm}`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
              //console.log("hello world :", data);
              if(data){
                if(data.content.length > 0){
                  this._state.totalRecords = data.totalElements
                  this.listData = data.content;
                }else{
                  this._state.totalRecords = 0;
                  this.listData = data.content;
                }
                this._search$.next();
              }
            },
            (error) => {
              console.log("error : ", error);
            }
          )
        }
    }


    public getCollections(){
        //console.log("### fetch run :", this.url);


          let urlWithParam = `${environment.api_url}/api/collection/list`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
              //console.log("collection List :", data);
              this._collections$.next(data);
            },
            (error) => {
              console.log("error : ", error);
            }
          )

    }

    public getCategories(){
        //console.log("### fetch run :", this.url);


          let urlWithParam = `${environment.api_url}/api/category/list`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
              console.log("category List :", data);
              this._categories$.next(data);
            },
            (error) => {
              console.log("error : ", error);
            }
          )

    }
}
