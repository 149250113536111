import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor() {
    this.isLoading.next(false);
  }
  setLoading(loading: boolean, url: string):void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.isLoading.next(true);
    }else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {


      setTimeout(() => {
        this.isLoading.next(false);
      }, 500);
    }
  }


}
