import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';



interface State {
    id:number;
    url:string;
    page: number;
    pageSize: number;
    searchTerm: string;
    startIndex: number;
    endIndex: number;
    totalRecords: number;
}



@Injectable({
    providedIn: 'root'
})

export class OrderService {
    private _search$ = new Subject<void>();



    private _alert$ = new BehaviorSubject<boolean>(false);
    private _list$ = new BehaviorSubject<any[]>([]);

    public data = new Array<any>();
    private _total$ = new BehaviorSubject<number>(0);
    public listData = new Array<any>();

    private _state: State = {
        id:0,
        url:'',
        page: 1,
        pageSize: 10,
        searchTerm: '',
        startIndex: 1,
        endIndex: 10,
        totalRecords: 0
    };

    constructor(private http: HttpClient) {
        this._search$.pipe(
            debounceTime(200),
            switchMap(() => this._search())
        ).subscribe(result => {
              this._list$.next(result.list);
            this._total$.next(result.total);
        });
    }

    get tables$() { return this._list$.asObservable(); }
    get alert$() { return this._alert$.asObservable(); }
    get id() { return this._state.id; }
    get url() { return this._state.url; }
    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get startIndex() { return this._state.startIndex; }
    get endIndex() { return this._state.endIndex; }
    get totalRecords() { return this._state.totalRecords; }




    set id(id: number) { this._set({ id }); }
    set url(url: string) { this._set({ url }); }
    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set startIndex(startIndex: number) { this._set({ startIndex }); }
    set endIndex(endIndex: number) { this._set({ endIndex }); }
    set totalRecords(totalRecords: number) { this._set({ totalRecords }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }


    private _set(patch: Partial<State>) {
      Object.assign(this._state, patch);
      if(patch.page ||
        patch.pageSize ||
        patch.searchTerm ||
        patch.url
      ){
        //console.log("set fetch run..", patch);
        this.fetch();
      }
    }


    private _search(): Observable<any> {
        const { pageSize, page, searchTerm } = this._state;
        let list = this.listData;
        let total = this.totalRecords;

        this._state.startIndex = (page - 1) * this.pageSize + 1;
        this._state.endIndex = (page - 1) * this.pageSize + this.pageSize;
        if (this.endIndex > this.totalRecords) {
            this.endIndex = this.totalRecords;
        }
    return of(
            { list, total }
          )
    }


    public getToken(){
      let url = `${environment.api_url}/api/address/auth`;
      this.http.get<any>(url).subscribe(
        (data)=>{
            console.log("nexlogi token check :", data);
        },
        (error) => {
          console.log("error : ", error);
        }
      )
    }

    public getDetail(id){
        //console.log("### fetch run :", this.url);

        let urlWithParam = `${environment.api_url}/api/address/${id}`;
        console.log("query url check :",urlWithParam );
        this.http.get<any>(urlWithParam).subscribe(
          (data)=>{
              console.log("add adddre detail check. :", data);
              //this.data = data;
              //this._search$.next();

          },
          (error) => {
            console.log("error : ", error);
          }
        )

    }

    public find(ref){

      console.log("########### check ref :", ref);

      let urlWithParam = `${environment.api_url}/api/order/ref`;
      return this.http.post<any>(urlWithParam, ref);

    }

    public create(order){

      console.log("order create :", order);
      let urlWithParam = `${environment.api_url}/api/order`;
      return this.http.post<any>(urlWithParam, order);

    }

    public update(order){

      console.log("order create :", order);
      let urlWithParam = `${environment.api_url}/api/order/status/${order.id}`;
      return this.http.put<any>(urlWithParam, order);

    }


    public updateOrderItemStatus(form){
      let urlWithParam = `${environment.api_url}/api/order/item`;
      //console.log("query url check :",urlWithParam );
      this.http.put<any>(urlWithParam, form).subscribe(
        (data)=>{
            this.fetch();
            //this.data = data;
            //this._search$.next();
            //this._alert$.next(true);
        },
        (error) => {
          console.log("error : ", error);
        }
      )
    }

    public generateSettlement(ids){
      console.log("ids check :", ids);
      let urlWithParam = `${environment.api_url}/api/settlement`;
      //console.log("query url check :",urlWithParam );
      this.http.post<any>(urlWithParam, ids).subscribe(
        (data)=>{
          if(data != null){
            alert("The settlement statement has been created successfully.");
            console.log("hello world :", data);
            this.listData = data;
            this._search$.next();
          }else{
            alert("The settlement statement creation failed. Please try again and contact the administrator if the problem persists.");
          }
        },
        (error) => {
          alert("The settlement statement creation failed. Please try again and contact the administrator if the problem persists.");
          console.log("error : ", error);
        }
      )
    }

    public fetch(){
        //console.log("### fetch run :", this.url);
        if(this.url){

          let urlWithParam = `${environment.api_url}/api/${this.url}?page=${this._state.page-1}&size=${this._state.pageSize}&${this._state.searchTerm}`;
          //console.log("query url check :",urlWithParam );
          this.http.get<any>(urlWithParam).subscribe(
            (data)=>{
              console.log("hello world :", data);
              if(data){
                if(data.content){
                  if(data.content.length > 0){
                    this._state.totalRecords = data.totalElements
                    this.listData = data.content;
                  }else{
                    this._state.totalRecords = 0;
                    this.listData = data.content;
                  }
                }else{
                  this.listData = data;
                }
                this._search$.next();
              }
            },
            (error) => {
              console.log("error : ", error);
            }
          )
        }
    }
}
