import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductDetailService } from './product-detail.service';
import { CartService } from '../cart/cart.service';
import { CartItem } from '../cart/cart.item.model';

import { ProductModel } from '../products/products.model';
import { productList } from '../products/data';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [ProductDetailService, CartService]
})
export class ProductDetailComponent implements OnInit {

  productDetail: any;
  id:number;
  rating:number;
  activeId = 0;
  // breadcrumb items
  breadCrumbItems: Array<{}>;
  data$: Observable<any>;
  loading: Observable<boolean>;
  reviewFlag: boolean;
  reviewForm: FormGroup;
  orderQty:number;
  alertMsg:string;
  alert$: Observable<boolean>;
  selectedOptionId:number;

  constructor(
    private route: ActivatedRoute,
    public service: ProductDetailService,
    public cartService:CartService,
    private formBuilder: FormBuilder) {

    this.data$ = service.data$;
    this.loading = service.loading$;


    this.alert$ = cartService.alert$;

    this.data$.subscribe(
      (data)=> {
        if(data){
          this.productDetail = data
          this.selectedOptionId = data.variants[0].id;
          //console.log("### select id check :", this.selectedOptionId);
        }
      });

    this.resetReviewForm();

    this.orderQty = 1;

  }

  ngOnInit(): void {
    //this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Product Detail', active: true }];
    this.route.params.subscribe(p => {
        //console.log("### rouote params check :", p);
        //this.parentId = p.parentId;
        this.id = p.id;
        this.service.id = p.id;
        this.service.url = `product/${this.id}`;
      })
      window.scrollTo(0, 0);
      this.alertMsg = "The selected product has been added to your shopping cart.";
      //this.cartService.setAlert(true);
  }

  toggleReview(){
    this.reviewFlag = !this.reviewFlag;
  }

  starRating(rating){
    this.rating = rating;
  }


  resetReviewForm(){
    this.reviewForm = this.formBuilder.group({
        writer: this.formBuilder.control('', []),
        email: this.formBuilder.control('', []),
        title: this.formBuilder.control('', []),
        content: this.formBuilder.control('', [])
        /*
        phone: this.formBuilder.control(this.profile.phoneNumber, [
          Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$'),
        ]),
        */
    });
    this.reviewFlag = true;
  }

  addToCart(){
    console.log("add to cart call..:", this.productDetail);
    let item = new CartItem();
    //this.cartService.addToCart(this.productDetail);
    //item.variantId = this.productDetail.variants[0].id;
    item.variantId = this.selectedOptionId;
    item.quantity = this.orderQty;
    //item.price = this.productDetail.variants[0].priceV2;
    //item.productTitle = this.productDetail.title;
    //item.variantTitle = this.productDetail.variants[0].title;
    //item.imageUrl = this.productDetail.featuredImage.url;


    //console.log("add to cart check :", item);
    this.cartService.addToCart(item);
  }


  closeAlert(){
    this.cartService.setAlert(false);
  }
  writeReview(){
    let form = this.reviewForm.value;
    form.productId = this.id;
    form.rating = this.rating;
    this.service.uploadReview(form);
    this.resetReviewForm();
    //console.log("#### review form check :", form);
/*
    form["productId"] = this.id;
    form["rating"] = this.rating;
    form["title"] = "Test review";
    form["content"] = "This is test review";
    form["writer"] = "James kim";
    form["email"] = "jiwokim@gmail.com";
*/


  }
}
