import { Component, OnInit } from '@angular/core';

import { productList } from './data';
import { ProductModel } from './products.model';
import { ProductsService } from './products.service';
import { Options } from 'ng5-slider';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductsService]
})
export class ProductsComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  //productList: ProductModel[];
  data$: Observable<any>;
  title:string;
  id:number;
  total$: Observable<number>;
  collections$:Observable<any[]>;
  categories$:Observable<any[]>;
  pricevalue = 250;
  minVal = 100;
  maxVal = 800;
  priceoption: Options = {
    floor: 0,
    ceil: 1000,
    translate: (value: number): string => {
      return '$' + value;
    },
  };

  isCollapsed: boolean;
  collapsed: boolean;
  collapsed3: boolean;
  searchText:string;
  categoryId:number;

  constructor(
      private route: ActivatedRoute,
      public service: ProductsService,
      private router: Router
  ) {
    this.data$ = service.tables$;

    this.total$ = service.total$;
    this.collections$ = service.collections$;
    this.categories$ = service.categories$
    this.searchText = '';
    this.categoryId = 0;
    this.collections$.subscribe(
      (data)=>{
        data.forEach(element => {
          if(element.id == this.id){
            this.title = element.title;
          }
        });
      }
    )
    //this.title = "hello..";
  }





  ngOnInit(): void {
    //this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Product', active: true }];
    //this.products = Object.assign([], productList);

    this.route.params.subscribe(p => {
      this.id = p.id;
      console.log("### rouote params check :", this.id);


    })
    //this.isCollapsed = true;
    //this.collapsed = true;
    //this.collapsed3 = true;
    //this.productList = productList;

    if(this.id){
      this.service.id = this.id;
    }else{
      this.service.url = 'product/';
    }
    window.scrollTo(0, 0);
  }

  /**
   * search the record
   * @param e element
   */

   routing(categoryId, title, parentId){
     console.log("routing check :", title);

     if(parentId){
       this.categoryId = parentId;
     }

     //this.active = index;
     //this.status = status;
     //let searchUrl = `status=${status}`;
     //console.log("### searchUrl check :", searchUrl);
     //this.service.searchTerm = searchUrl;
     this.title = title;
     this.service.searchTerm = `categoryId=${categoryId}`;
     /*
     this.searchText = '';
     this.service.searchTerm = this.searchText;
     this.id = collectionId;
     if(collectionId){
       //console.log("################# collection id exist :", collectionId);
       this.service.id = this.id;
     }else{
       this.title = null;
       this.service.url = 'product/';

     }
     */
   }


  searchFilter(e) {
    //const searchStr = e.target.value;
    this.searchText = e.target.value;
    //console.log("### search string check :", searchStr);
    let searchUrl = `title=${this.searchText}`;
    //console.log("### searchUrl check :", searchUrl);
    this.service.searchTerm = searchUrl;
  }

  /***
   * slider change fetch the record
   */


  valueChange(value: number, boundary: boolean): void {
    if (boundary) {
      this.minVal = value;
    } else {
      this.maxVal = value;
      /*
      this.products = productList.filter(function(product) {
        return product.disRate <= value && product.disRate >= this;
      }, this.minVal);
      */
    }
  }

}
