import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { CustomerAuthGuard } from './core/guards/customer.auth.guard';
import { ShopAuthGuard } from './core/guards/shop.auth.guard';


import { LayoutComponent } from './layouts/layout/layout.component';
import { ShoppingLayoutComponent } from './layouts/shopping/layout.component';


const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: 'admin', component: LayoutComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'shop', component: LayoutComponent, loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule), canActivate: [AuthGuard] },
  { path: 'customer', component: LayoutComponent, loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule), canActivate: [CustomerAuthGuard] },
  //{ path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },
  { path: '', component: ShoppingLayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
