<div class="container-fluid">



  <div class="row mb-2">
  <div class="col-12">
  <app-pagetitle title="Orders" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  </div>

  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">



          <div class="table-responsive mt-3">

            <table class="table table-centered table-hover dt-responsive nowrap  no-footer dtr-inline">
                <thead class="thead-dark">
                  <tr>
                    <th>Order no.</th>
                    <th>Order date</th>

                    <th class="text-center">Itmes</th>
                    <th class="text-right">Total</th>


                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let table of data$ | async ;let line = index">
                  <tr>
                    <td ><ngb-highlight [result]="table.id"></ngb-highlight></td>
                    <td ><ngb-highlight [result]="table.createdAt |  date:'dd/MM/yyyy HH:mm'"></ngb-highlight></td>

                    <!--
                    <td ><ngb-highlight [result]="table.items?.length"></ngb-highlight> items</td>
                    -->

                    <td>
                        <ul class="list-group list-group-flush">
                          <li
                            *ngFor="let item of table.items"
                            class="list-group-item">
                            <div class="row">
                              <div class="col-2 text-right">
                                <img
                                  src="{{item.product.featuredImage.url}}"
                                  class="avatar-sm mr-2">
                              </div>
                              <div class="col-6">
                                <div>{{item.product.title}}</div>
                                <div>{{item.variant.title}}</div>

                              </div>
                              <div class="col-2"> X {{item.quantity}}</div>
                              <div class="col-2 text-center">
                                <span
                                  *ngIf="item.status != 'DISPATCHED'"
                                  class="badge badge-pill pt-1 {{item.status=='ORDER'?'badge-secondary':'badge-primary'}}"
                                  >{{item.status}}</span>

                                  <button
                                    *ngIf="item.status == 'DISPATCHED'"
                                    type="button"
                                    (click)="confirmDelivery(item)"
                                    class="btn btn-success btn-sm"
                                    >CONFIRM DELIVERY</button>


                              </div>

                            </div>


                          </li>


                        </ul>


                    </td>


                    <td class="text-right">$ <ngb-highlight [result]="table.totalAmount"></ngb-highlight></td>

                  </tr>
                  </ng-container>
                </tbody>
              </table>
              <!-- End table -->
              <div class="row justify-content-md-between align-items-md-center mt-2"
                style="width:100%;"
              >
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Showing
                    {{service.startIndex}} to
                    {{service.endIndex}} of {{service.totalRecords}}
                    entries
                  </div>
                </div>
                <div class="col-sm-12 col-md-5">
                  <div class="text-md-right float-md-right pagination-rounded">
                    <ngb-pagination
                      [collectionSize]="total$ | async"
                      [maxSize]="5"
                      [ellipses]="false"
                      [(page)]="service.page"
                      [pageSize]="service.pageSize">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
  <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
