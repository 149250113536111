import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, Customer } from '../models/auth.models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    public currentCustomer: BehaviorSubject<Customer>;
    //public currentCustomer: Observable<Customer>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentCustomer = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentCustomer')));
        //this.currentCustomer = this.currentCustomerSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    /*
    public get currentCustomerValue(): Customer {
        return this.currentCustomerSubject.value;
    }
    */

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.api_url}/api/auth`,{ email: email, password: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    console.log("### user check :", user);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    customerLogin(email: string, password: string) {
      return this.http.post<any>(`${environment.api_url}/api/auth/customer`,{ email: email, password: password })
          .pipe(map(customer => {
              // login successful if there's a jwt token in the response
              if (customer && customer.token) {
                  localStorage.setItem('currentCustomer', JSON.stringify(customer));
                  this.currentCustomer.next(customer);
                  return customer;
              }else{
                  return null;
              }

          }))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }


    customerLogout() {
      console.log("#### customerLogout call..");
        // remove user from local storage to log user out
        localStorage.removeItem('currentCustomer');
        localStorage.removeItem('cart');
        this.currentCustomer.next(null);
    }
}
