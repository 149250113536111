<div class="container-fluid">
  <app-pagetitle title="Video" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Responsive embed video 16:9</h4>
          <p class="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
          <!-- 16:9 aspect ratio -->
          <div class="embed-responsive embed-responsive-16by9">
            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" class="embed-responsive-item"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Responsive embed video 21:9</h4>
          <p class="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
          <!-- 21:9 aspect ratio -->
          <div class="embed-responsive embed-responsive-21by9">
            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" class="embed-responsive-item"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Responsive embed video 4:3</h4>
          <p class="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
          <!-- 4:3 aspect ratio -->
          <div class="embed-responsive embed-responsive-4by3">
            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" class="embed-responsive-item"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Responsive embed video 1:1</h4>
          <p class="card-title-desc">Aspect ratios can be customized with modifier classes.</p>
          <!-- 1:1 aspect ratio -->
          <div class="embed-responsive embed-responsive-1by1">
            <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ" class="embed-responsive-item"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
