export class Payment {
    Amount: number;
    MerchantReference: string;
}



export class PaymentResponse {
    success: boolean;
    navigateURL: string;
    errorCode: number;
    errorMessage: string;
    transactionRefNo: string;
}
