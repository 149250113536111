import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Customer } from '../models/auth.models';
import { environment } from '../../../environments/environment';


interface State {
    id:number;
    url:string;
}



@Injectable({ providedIn: 'root' })


export class CustomerProfileService {

  private _search$ = new Subject<void>();
  private _alert$ = new BehaviorSubject<boolean>(false);
  private _data$ = new BehaviorSubject<any>(null);
  public data = new Array<any>();

  private _state: State = {
      id:0,
      url:'',
  };

  constructor(private http: HttpClient) {
      this._search$.pipe(
          debounceTime(200),
          switchMap(() => this._search())
      ).subscribe(result => {
          this._data$.next(result.data);

      });
  }

  get data$() { return this._data$.asObservable(); }
  get alert$() { return this._alert$.asObservable(); }
  get id() { return this._state.id; }
  get url() { return this._state.url; }

  set id(id: number) { this._set({ id }); }
  set url(url: string) { this._set({ url }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    //console.log("################ set path :", patch);
    this.fetch();
  }


  private _search(): Observable<any> {
      let data = this.data;
      return of(
          { data }
        )
  }



  public fetch(){
      //console.log("### fetch run :", this.url);
      if(this.url){
        let urlWithParam = `${environment.api_url}/api/${this.url}`;

        //console.log("query url check :",urlWithParam );


        this.http.get<any>(urlWithParam).subscribe(
          (data)=>{
              console.log("add product service :", data);
              this.data = data;
              this._search$.next();

          },
          (error) => {
            console.log("error : ", error);
          }
        )
      }
  }

  public setAlert(flag){
    this._alert$.next(flag);
  }

  public update(form){
      //console.log("### update run :", this.url);

      if(this.url){
        let urlWithParam = `${environment.api_url}/api/${this.url}`;
        console.log("query url check :",urlWithParam );
        this.http.put<any>(urlWithParam, JSON.stringify(form)).subscribe(
          (data)=>{
              console.log("add product service :", data);
              this.data = data;
              this._search$.next();
              this._alert$.next(true);
          },
          (error) => {
            console.log("error : ", error);
          }
        )
      }
    }

    getAll() {
        return this.http.get<Customer[]>(`/api/customer/login`);
    }

    register(customer: Customer) {
      console.log("@@@ customer service register call..",JSON.stringify(customer));
      //user.type = 'member';
      //return this.http.post(`${environment.api_url}/api/auth/customer/signup`,JSON.stringify(customer));
      return this.http.post(`${environment.api_url}/api/auth/customer/signup`,customer);

    }
}
