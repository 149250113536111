import { Component, OnInit } from '@angular/core';


import { ProductModel } from '../products/products.model';
import { ProductsService } from '../products/products.service';
import { Options } from 'ng5-slider';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {Address} from '../../../model/address.model';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  providers: [ProductsService]
})
export class CollectionsComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  //productList: ProductModel[];
  data$: Observable<any>;
  title:string;
  id:number;
  total$: Observable<number>;
  collections$:Observable<any[]>;
  pricevalue = 250;
  minVal = 100;
  maxVal = 800;
  priceoption: Options = {
    floor: 0,
    ceil: 1000,
    translate: (value: number): string => {
      return '$' + value;
    },
  };

  isCollapsed: boolean;
  collapsed: boolean;
  collapsed3: boolean;
  searchText:string;

  constructor(
      private route: ActivatedRoute,
      public service: ProductsService,
      private router: Router
  ) {
    this.data$ = service.tables$;

    this.total$ = service.total$;
    this.collections$ = service.collections$;
    this.searchText = '';

    this.collections$.subscribe(
      (data)=>{
        data.forEach(element => {
          if(element.id == this.id){
            this.title = element.title;
          }
        });
      }
    )
    //this.title = "hello..";
  }


  routing(collectionId){
    //console.log("status check :", status);
    //this.active = index;
    //this.status = status;
    //let searchUrl = `status=${status}`;
    //console.log("### searchUrl check :", searchUrl);
    //this.service.searchTerm = searchUrl;
    this.searchText = '';
    this.service.searchTerm = this.searchText;
    this.id = collectionId;
    if(collectionId){
      //console.log("################# collection id exist :", collectionId);
      this.service.id = this.id;
    }else{
      this.title = null;
      this.service.url = 'product/';

    }

  }



  ngOnInit(): void {
    //this.breadCrumbItems = [{ label: 'Ecommerce' }, { label: 'Product', active: true }];
    //this.products = Object.assign([], productList);

    this.route.params.subscribe(p => {
      this.id = p.id;
      //console.log("### rouote params check :", this.id);


    })
    //this.isCollapsed = true;
    //this.collapsed = true;
    //this.collapsed3 = true;
    //this.productList = productList;

    if(this.id){
      this.service.id = this.id;
    }else{
      this.service.url = 'product/';
    }
    window.scrollTo(0, 0);
  }

  /**
   * search the record
   * @param e element
   */
  searchFilter(e) {
    //const searchStr = e.target.value;
    this.searchText = e.target.value;
    //console.log("### search string check :", searchStr);
    let searchUrl = `title=${this.searchText}`;
    //console.log("### searchUrl check :", searchUrl);
    this.service.searchTerm = searchUrl;
  }

  /***
   * slider change fetch the record
   */


  valueChange(value: number, boundary: boolean): void {
    if (boundary) {
      this.minVal = value;
    } else {
      this.maxVal = value;
      /*
      this.products = productList.filter(function(product) {
        return product.disRate <= value && product.disRate >= this;
      }, this.minVal);
      */
    }
  }

}
